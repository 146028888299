<template>
  <div>
    <v-card>
      <v-card-title>{{$t('t.Invoices')}}</v-card-title>
      <v-card-text>
        <date-filter-sum
          :document.sync="computedInvoiceParams"
          :dense="dense"
          :required="isRequired"
          view-type="invoices"
          ref="component1"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>{{$t('t.UnappliedCashes')}}</v-card-title>
      <v-card-text>
        <date-filter
          :document.sync="computedUCParams"
          :dense="dense"
          :required="isRequired"
          view-type="unapplied-cashes"
          ref="component2"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>{{$t('t.WorkInProgressItems')}}</v-card-title>
      <v-card-text>
        <date-filter
          :document.sync="computedWipParams"
          :dense="dense"
          :required="isRequired"
          view-type="work-in-progress-items"
          ref="component3"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    DateFilterSum: () => import('./date-filter-sum'),
    DateFilter: () => import('./date-filter')
  },
  computed: {
    computedInvoiceParams: {
      get () {
        return this.dataInvoices
      },
      set (v) {
        this.dataInvoices = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedUCParams: {
      get () {
        return this.dataUnappliedCashes
      },
      set (v) {
        this.dataUnappliedCashes = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedWipParams: {
      get () {
        return this.dataWorkInProgresses
      },
      set (v) {
        this.dataWorkInProgresses = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    isRequired () {
      return (!(this.computedInvoiceParams?.columnDate && this.computedInvoiceParams?.columnSum) &&
        !this.computedUCParams?.columnDate && !this.computedWipParams?.columnDate) ||
        (this.computedInvoiceParams?.columnDate && !this.computedInvoiceParams?.columnSum) ||
        (!this.computedInvoiceParams?.columnDate && this.computedInvoiceParams?.columnSum)
    }
  },
  data () {
    return {
      required: [v => !this.isRequired || this.$t('t.IsRequired')],
      dataInvoices: null,
      dataUnappliedCashes: null,
      dataWorkInProgresses: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'AgingAccountParams', c: { invoices: this.dataInvoices, unappliedCashes: this.dataUnappliedCashes, workInProgresses: this.dataWorkInProgresses }
      }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataInvoices = v?.c?.invoices ?? null
        this.dataUnappliedCashes = v?.c?.unappliedCashes ?? null
        this.dataWorkInProgresses = v?.c?.workInProgresses ?? null

        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
